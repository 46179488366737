
export const reccomShop = [
    {
        id: 1,
        name: "Beautrium",
        logo: "/images/logo.jpg",
        link : "/beautrium"
    },
    {
        id: 2,
        name: "Watsons",
        logo: "/images/watsonlogo.jpg", // โลโก้ร้าน Beautrium
        link : "/watsons"
    },
    {
        id: 3,
        name: "Konvy",
        logo: "/images/konvylogo.jpg", // โลโก้ร้าน Konvy
        link : "/konvy"
    },
    {
        id: 4,
        name: "Boots",
        logo: "/images/bootslogo.jpg", // โลโก้ร้าน Boots
        link : "/boots"

    },
    {
        id: 5,
        name: "Evenboy",
        logo: "/images/evenboylogo.jpg", // โลโก้ร้าน Evenboy
        link: "/eveanboy"
    },
];
