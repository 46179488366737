
export const productsWatsons = [
    {
        id: 1,
        name: "Skintific Perfect Stay Velvet Matte Cushion 02 IVORY",
        description: "คุชชั่นที่ให้ผิวเรียบเนียน แมตต์ไร้ที่ติ ให้ผิวแลดูสุขภาพดี ปกปิดได้แม้รอยดำฝังลึก รอยคล้ำใต้ตา และจุดบกพร่องบนผิวใน 1 แตะ ด้วยสูตรที่ช่วยเติมความชุ่มชื้นพร้อมคุมความมันตลอด 12 ชั่วโมง",
        price: 580,
        quantity: 10,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Skintific",
        shop: "Watsons",
        image: "/images/1.jpg"
    },
    {
        id: 2,
        name: "L'Oreal True Match Liquid Foundation N1",
        description: "ปกปิดทนนาน สบายผิวหน้า  เนื้อสีที่กลมกลืนกับสีผิวจริง ให้คุณเผยสีผิวธรรมชาติ เนียน ไร้ที่ติ ปกป้องผิวจากแสงแดดด้วย SPF/16PA++",
        price: 499,
        quantity: 6,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "L'Oreal",
        shop: "Watsons",
        image: "/images/2.jpg"
    },
    {
        id: 3,
        name: "LA GLACE Starry Majesty Glow Cushion 01 Warm Vanilla",
        description: "ฟินิชผิวสวย ผ่องขึ้นกล้อง ให้งานผิวโกลว์ พร้อมมอบงานผิวผ่อง Better skin แบบผิวที่ 2 มี SPF 15 PA++++ การปกปิดระดับ Light to medium",
        price: 389,
        quantity: 9,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "LA GLACE",
        shop: "Watsons",
        image: "/images/3.jpg"
    },
    {
        id: 4,
        name: "Canmake illuminating finish powder abloom",
        description: "ช่วยปรับสีผิวให้เปล่งประกายแวววาวราวเปิดแฟลชให้ผิวสว่างสวยทาเพียงครั้งเดียวในบริเวณที่ต้องการก็ช่วยเติมความกระจ่างใสได้อย่างยาวนานคุณสมบัติช่วยเบลอรูขุมขน ทำให้ผิวที่ได้สวยเนียนเสมือนใส่ฟิลเตอร์ให้ผิวเอกลักษณ์เนื้อแป้ง 5 เฉดสี",
        price: 520,
        quantity: 13,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Canmake",
        shop: "Watsons",
        image: "/images/4.jpg"
    },
    {
        id: 5,
        name: "Rom&nd better than palette 01 pampas garden",
        description: "พาเลทอายแชโดว์ 10 เฉดสี มีทั้งเนื้อแมท ชิมเมอร์ และกลิตเตอร์ เกลี่ยง่าย เม็ดสีแน่น ติดทนนาน ช่วยเพิ่มความโดดเด่นให้กับดวงตา",
        price: 890,
        quantity: 11,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Rom&nd",
        shop: "Watsons",
        image: "/images/5.jpg"
    },
    {
        id: 6,
        name: "Meilinda Your Candy Dream Palette 02 Candy Floss",
        description: "พาเลทท์อายแชโดว์เนื้อแมทท์และชิมเมอร์โทนพีชพาสเทล เฉดวอร์มโทนหวานละมุนชวนฝันที่ออกแบบมาให้ใช้งานได้ง่ายสำหรับสาวไทย",
        price: 399,
        quantity: 8,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Meilinda",
        shop: "Watsons",
        image: "/images/6.jpg"
    },
    {
        id: 7,
        name: "Gala Camille Spinning Lubric Lip 05 Rule Break",
        description: "ลิป Tint Oil เนื้อลื่น ทิ้ง Stain ติดทนขั้นสุด ให้สีชัด นุ่มละมุน ไม่หนักปาก ไม่แห้งตึง",
        price: 299,
        quantity: 9,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Gala Camille",
        shop: "Watsons",
        image: "/images/7.jpg"
    },
    {
        id: 8,
        name: "Maybelline New York The Hyper curl Waterproof Mascara Black",
        description: "ช่วยให้ขนตาของคุณสาวๆ โค้งงอนขึ้น 75 หนาขึ้น 3 เท่าแบบทันใจอย่างเห็นได้ชัด ด้วยขนแปรงแบบพิเศษของ Maybelline ที่สามารถเข้าถึงขนตาได้แบบเส้นต่อเส้น",
        price: 179,
        quantity: 18,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Maybelline",
        shop: "Watsons",
        image: "/images/8.jpg"
    },
    {
        id: 9,
        name: "Srichand Feelin' Me Matte Liquid Lip 3g 04 Crush on Pink",
        description: "ลิปเนื้อแมทต์ เกลี่ยง่าย สีชัด ติดทน กลบสีริมฝีปากได้เนียนสนิท ผสานคุณค่าการบำรุงให้ปากแลดูชุ่มชื่นน่าสัมผัส",
        price: 199,
        quantity: 10,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Srichand",
        shop: "Watsons",
        image: "/images/9.jpg"
    },
    {
        id: 10,
        name: "Lifeford Paris Extreme Eyeliner Super Black",
        description: "อายไลเนอร์รุ่นใหม่ สีดำสนิทสูตรเข้มข้น เส้นคมชัด เม็ดสีแน่น ด้วยความเรียวเล็กของหัวปากกาช่วยให้กรีดตาได้อย่างง่ายดาย ไม่เลอะ ไม่เหนียว แห้งเร็ว ติดทนนานตลอดวัน",
        price: 129,
        quantity: 12,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Lifeford",
        shop: "Watsons",
        image: "/images/10.jpg"
    },
    {
        id: 11,
        name: "Vaseline Lip Therapy Rosy Tinted Lip Balm 10ml",
        description: "ช่วยเพิ่มและกักเก็บความชุ่มชื่น เพื่อให้ริมฝีปากที่แห้งแตก อ่อนนุ่มชุ่มชื่นขึ้นทันทีถึง 2 เท่า พร้อมให้ริมฝีปากอมชมพูอวบอิ่มแวววาว",
        price: 99,
        quantity: 18,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "makeUp",
        brand: "Vaseline",
        shop: "Watsons",
        image: "/images/11.jpg"
    },
    {
        id: 12,
        name: "Cerave Facial Lotion PM 52ml",
        description: "เนื้อบางเบา ไม่เหนียวเหนอะหนะ · ฟื้นบำรุงปราการผิวตามธรรมชาติ · มอบความชุ่มชิ้นได้ยาวนานกว่า 24 ชั่วโมง · ปราศจากน้ำหอม ไม่ก่อให้เกิดการอุดตัน ",
        price: 690,
        quantity: 15,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Cerave",
        shop: "Watsons",
        image: "/images/12.jpg",
        featured: true
    },
    {
        id: 13,
        name: "Clear Nose Moist Skin Barrier Moisturizing Gel 120ml",
        description: "มอยส์เจอไรเซอร์ ผิวชุ่มชื้น ลดผิวแห้ง ผิวแห้ง ไม่มันเยิ้ม เกราะป้องกันผิว กระชับรูขุมขน ไม่อุดตัน หน้าใส ผิวใส กระจ่างใส ริ้วรอย รอยดำ รอยสิว ฝ้า กระ จุดด่างดำ ขาว ผิวแพ้ง่าย ",
        price: 999,
        quantity: 15,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Clear Nose",
        shop: "Watsons",
        image: "/images/13.jpg"
    },
    {
        id: 14,
        name: "Physiogel Soothing Care A.I. Light Cream 50ml",
        description: "ผสานสารลดระคายเคืองผิว และสารให้ความชุ่มชื้น สำหรับที่บอบบางแพ้ง่าย เนื้อสัมผัสบางเบา ควบคุมความมัน ไม่เหนอะหนะ ไม่อุดตันรูขุมขน ปรับสมดุลให้ผิวสุขภาพดี",
        price: 970,
        quantity: 12,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Physiogel",
        shop: "Watsons",
        image: "/images/14.jpg"
    },
    {
        id: 15,
        name: "Laroche Posay Effaclar Duo+M 40ml",
        description: " มอยซ์เจอไรเซอร์บำรุงผิวเป็นสิว ลดรอยสิว ดูแลผิวมีปัญหาสิว",
        price: 1090,
        quantity: 13,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Laroche-Posay",
        shop: "Watsons",
        image: "/images/15.jpg"
    },
    {
        id: 16,
        name: "Cetaphil bright healthy radiance brightness refresh toner 150m",
        description: "โทนเนอร์ทำความสะอาดผิวหน้า ปรับสีผิวให้สม่ำเสมอ เรียบเนียนยิ่งขึ้น พร้อมทั้งฟื้นฟูผิวเสียให้กลับมาแข็งแรง รู้สึกสดชื่นและมีสุขภาพดี",
        price: 499,
        quantity: 11,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Cetaphil",
        shop: "Watsons",
        image: "/images/16.jpg"
    },
    {
        id: 17,
        name: "Dr.G red blemish clear soothing cream 70ml",
        description: "ครีมบำรุงผิวสำหรับทุกสภาพผิว ใช้ได้แม้ผิวแพ้ง่าย บำรุงล้ำลึก ปลอบประโลมผิวที่ระคายเคือง ลดอาการแดงและอาการอักเสบ",
        price: 1090,
        quantity: 19,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Dr.G",
        shop: "Watsons",
        image: "/images/17.jpg"
    },
    {
        id: 18,
        name: "INGU green tea retinol repair shot 15ml",
        description: "เซรั่มลดเลือนริ้วรอยด้วย Encapsulated Retinol เรตินอลรูปแบบแคปซูลที่มีความคงทนและอ่อนโยนกว่าเรตินอลทั่วไป",
        price: 390,
        quantity: 18,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "INGU",
        shop: "Watsons",
        image: "/images/18.jpg"
    },
    {
        id: 19,
        name: "Skin1004 madagascar centella ampoule 55ml",
        description: "ฟื้นบำรุงผิวด้วยพลังของ Centella Asiatica สารสกัดบริสุทธิ์จากมาดากัสการ์ 100 % ดูแลผิวที่อ่อนแอพร้อมฟื้นบำรุงผิวให้แข็งแรงสุขภาพดีอย่างอ่อนโยน ",
        price: 790,
        quantity: 10,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Skin1004",
        shop: "Watsons",
        image: "/images/19.jpg"
    },
    {
        id: 20,
        name: "Eucerin spotless brightening booster serum 30ml",
        description: "นวัตกรรมเซรั่มลดจุดด่างดำอันดับ 1 จากยูเซอริน ซึมซาบล้ำลึกสู่ชั้นผิวผสานการ ทำงานของ THIAMIDOL สารที่ได้รับการจดสิทธิบัตรเอกสิทธิ์ เฉพาะของยูเซอริน",
        price: 2100,
        quantity: 10,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Eucerin",
        shop: "Watsons",
        image: "/images/20.jpg"
    },
    {
        id: 21,
        name: "Mizumi Cica Soothing Moisture Gel 45ml",
        description: "มอยเจอร์ไรเซอร์ สูตรเข้มข้น สำหรับผิวมัน เป็นสิวง่าย 1. Madagascar Cica เข้มข้น 2 สารสกัดใบบัวบกออร์แกนิคจากมาดากัสการ์",
        price: 609,
        quantity: 13,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "Mizumi",
        shop: "Watsons",
        image: "/images/21.jpg"
    },
    {
        id: 22,
        name: "Cerave Skin Renewing Vitamin C Serum 30ml",
        description: "วิตามินซีเซรั่มเข้มข้น 10 ผสานเซราไมด์ เสริมเกราะปกป้องผิวแข็งแรง ลดเลือนริ้วรอย เผยผิวดูกระจ่างใส",
        price: 990,
        quantity: 8,
        date: "05-11-2567",
        Updated: "08-11-2567",
        category: "Skincare",
        brand: "CeraVe",
        shop: "Watsons",
        image: "/images/22.jpg"
    }
];
