export const shopdata = [
    {
        id: 1,
        name: "Beautrium",
        image :"/images/logo.jpg",
        description: "เป็นร้านมัลติแบรนด์ที่รวบรวมผลิตภัณฑ์ความงามและเครื่องสำอางมากมายจากหลากหลายแบรนด์ชั้นนำ ทั้งไทยและต่างประเทศ เพื่อตอบโจทย์ความต้องการที่หลากหลายของลูกค้า ไม่ว่าจะเป็นสกินแคร์ เครื่องสำอาง น้ำหอม รวมถึงอุปกรณ์เสริมสวยต่าง ๆ ภายในร้านมีการจัดหมวดหมู่สินค้าให้เลือกอย่างสะดวก มีบรรยากาศที่ทันสมัยและเชิญชวน พร้อมด้วยพนักงานที่พร้อมให้คำแนะนำอย่างมืออาชีพ อีกทั้งยังมีโปรโมชั่นและส่วนลดที่น่าสนใจสำหรับนักช้อปที่ชื่นชอบสินค้าแบรนด์เนมในราคาที่เข้าถึงได้",
        phone :"+66 2 055 0550",
        address: "Seacon Bangkae, Bangkok 10160",
        contact: "BEAUTRIUM บิวเทรี่ยม Facebook page"
    },

];