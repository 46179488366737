// src/components/ProductList.js
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // นำเข้า Link จาก react-router-dom

const products = [
  { id: 1, name: 'Anessa Perfect Uv Sunscreen Skincare Milk Na Spf 50+ Pa++++ 60Ml อเนสซ่าเพอร์เฟค ยูวีซันสกรีน สกินแคร์มิลค์ 50+ พีเอ++++', price: '$944', imageUrl: 'https://down-th.img.susercontent.com/file/th-11134201-7r992-m04qtcehfxkq34.webp' },
  { id: 2, name: 'MizuMi มิซึมิ ยูวี วอเตอร์ เซรั่ม เอสพีเอฟ 50+ พีเอ ++++ 40 กรัม', price: '฿445', imageUrl: 'https://medias.watsons.co.th/publishing/WTCTH-262540-front-U5AdcOS3-zoom.png?version=1730746217' },
  { id: 3, name: 'Acne Aid แอคเน่ เอด ลิควิด คลีนเซอร์ 100มล', price: '฿169', imageUrl: 'https://medias.watsons.co.th/publishing/WTCTH-295997-front-zoom.jpg?version=1716879767' },
  { id: 4, name: 'In2It อินทูอิท พลัมพิ่ง ลิป ออย 4ก', price: '฿199', imageUrl: 'https://medias.watsons.co.th/publishing/WTCTH-309206-front-9eHH7sEX-zoom.png?version=1730745188' },
  { id: 5, name: 'Senka เซนกะ เพอร์เฟ็ค วิป เฟรช 100 กรัม', price: '฿181', imageUrl: 'https://medias.watsons.co.th/publishing/WTCTH-263862-front-U3oUWyzl-zoom.png?version=1730746225' },
  { id: 6, name: 'ครีมกันแดด Mizumi มิซึมิ ยูวี วอเตอร์ ดีเฟนส์ โปร 40 กรัม', price: '฿890', imageUrl: 'https://medias.watsons.co.th/publishing/WTCTH-293471-front-zoom.jpg?version=1716868584' },
  { id: 7, name: 'สกินทิฟิค เอ็มเอสเฮช ไนอาซินาไมด์ ไบร์ทเทนนิ่ง มอยส์เจอร์ เจล 80 กรัม', price: '฿420', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/2/15/8d2b3f5d-b927-4d8b-9965-9e4a05585281_large.jpg' },
  { id: 8, name: 'บู๊ทส์ วิตามิน ซี ไบรท์เทนนิ่ง มอยส์เจอร์ไรซิ่ง ครีม เอสพีเอฟ30 พีเอ+++ 50มล.', price: '฿209', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2023/7/25/fd545bfc-697e-4005-a1c1-fa02a9158f20_large.jpg' },
  { id: 9, name: 'Skintific สคินทิฟฟิค โคฟเวอร์ ออล์ เพอร์เฟกต์ คุชชั่น 04ดับเบิ้ลยู วอร์ม เบจ', price: '฿449', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/9/19/9294896e-c77f-4b85-a508-0658ab6544b8_large.jpg' },
  { id: 10, name: 'เบบี้ไบร์ท ฟลอร่ามอยส์ทูแมทท์ลิป 3G #10 เรดทิวลิป Rated stars out of 5', price: '฿295', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/10/4/428ec5b3-6fa6-4618-b628-524560a587f8_large.jpg' },
  { id: 11, name: 'นีเวีย ลูมินัส630 แอนตี้สปอต แอดวานซ์ สปอต เซรั่ม 30 มล', price: '฿599', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/10/4/c9117dae-11d9-4051-9184-2cabd5b99899_large.jpg' },
  { id: 12, name: 'สกินทิฟิค ซิมไวท์ 377 ดาร์ก สปอต เซรั่ม เซรั่มลดเลือนจุดด่างดำ 50มล.', price: '฿640', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/5/29/7bbc35a7-cc0e-4731-a3fb-3e6614c49187_large.jpg' },
  { id: 13, name: 'การ์นิเย่ สกิน แนทเชอรัลส์ ไบรท์ คอมพลีท โอเวอร์ไนท์ บูสเตอร์ เซรั่ม 30มล.', price: '฿499', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2023/7/20/47328791-5f9b-40d6-a8dd-f10692036768_large.jpg' },
  { id: 14, name: 'Revlon เรฟลอน คัลเลอร์สเตย์ เอ็กซ์เทนชั่นแนร์ เลงเทนนิ่ง มาสคาร่า แบล็ค', price: '฿429', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/10/10/41648875-4802-4d0e-9fd9-0d3f60eec9ac_large.jpg' },
  { id: 15, name: 'บราวอิท อายดอลเอชดีอินเนอร์อายไลน์เนอร์ 0.1G #บราวน์', price: '฿259', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/10/4/5ada88f3-4e8c-4047-bf1f-5b6be644bb5d_large.jpg' },
  { id: 16, name: 'นัมเบอร์เซเว่น เรเดียนซ์ พลัส 15% วิตามิน ซี เซรั่ม 25มล.', price: '฿449', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2023/6/28/3dae819f-acd3-493b-9564-3ab1ea2ec1be_large.jpg' },
  { id: 17, name: 'นัมเบอร์เซเว่น ฟิวเจอร์ รีนิว ดาแมจ โพรเท็คชั่น ดีเฟนซ์ ชีลด์ เอสพีเอฟ 50 50 มล.', price: '฿659', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/10/30/f5641db6-9ccf-48d1-a344-1a5e480491c0_large.jpg' },
  { id: 18, name: 'เมย์เบลลีน นิวยอร์ก รองพื้น ฟิต มี แมท แอนด์ พอร์เลส ฟาวเดชั่น เฉด 228 30 มล.', price: '฿319', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/4/26/0aeffb88-ff66-48bb-aebf-545fb82efc9b_large.jpg' },
  { id: 19, name: 'เบบี้ไบร์ท ชีคโกลว์ซันฟลาวเวอร์บลัช 5.2G 02 บราวน์โรส', price: '฿119 ', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/1/26/6b27e7de-363e-4b86-9fa6-218c1d104de9_large.jpg' },
  { id: 20, name: 'เจนเทิลแมทท์คอลลาเจนลิปสติก 3.7g เบบี้ไบร์ท (M) #03 สตาร์ฟิช', price: '$119', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2022/12/20/0b67f700-bf53-48d1-a59a-94e321ececcd_large.jpg' },
  { id: 21, name: 'เบบี้ไบร์ท มูดแมทท์บลัชเชอร์ 4.5g #04 เลิฟ', price: '฿149', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2022/12/20/0271ce5c-2132-4cf6-9f2a-90948819624b_large.jpg' },
  { id: 22, name: 'บานิลาโค คลีน อิท ซีโร่ ออริจินอล คลีนซิ่ง บาล์ม 100มล.', price: '฿890', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/10/4/84bf7de2-8ff7-4d3b-b960-bc4175522181_large.jpg' },
  { id: 23, name: 'เอเวียง โกลว์ เฟเชียล มิสท์ 100 มล.', price: '฿599', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/10/4/e677c7a7-22a3-4d7a-91dc-1c2f0383c43d_large.jpg' },
  { id: 24, name: 'สกินทิฟิค 10% วิตามิน ซี ไบร์ทเทนนิ่ง โกลว์ เซรั่ม 20 มล', price: '฿427', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/2/15/f250f1c6-2221-4ea9-83e8-25ee1f2a275c_large.jpg' },
  { id: 25, name: 'เมย์เบลลีนอายแชโดว์พาเลทท์เดอะบรัชนู้ดส์ 9กรัม', price: '฿549', imageUrl: 'https://thmappbkk.blob.core.windows.net/boots/2024/4/26/3b9eb55c-94e0-4d48-a059-caa8941e2201_large.jpg' },
   
  // เพิ่มข้อมูลสินค้าอื่นๆ ได้ที่นี่
];

const ProductListBoots = () => {
  return (
    <div className="container mt-4">
      <Row>
        {products.map((product) => (
          <Col xs={6} md={2} className="mb-4" key={product.id}>
            {/* ลิงก์ไปยังหน้าแสดงรายละเอียดสินค้าโดยใช้ id */}
            <Link to={`/boots/product/${product.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Card>
                <Card.Img variant="top" src={product.imageUrl} alt={product.name} />
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>{product.price}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProductListBoots;
