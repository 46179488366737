import AutoScrollingBanner from "./AutoScrollingBanner"
import ReccomendShop from "./ReccomendShop"
import "./control.css"
export default function HomePage(){
    return(
        <div className="main-container">
            <AutoScrollingBanner/>
            <ReccomendShop/>
        </div>
    )
}