// src/pages/ProductDetail.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../pages/ProductDetail.css';

function ProductDetailWatsons({ product,onViewStore}) {
    if (!product) {
        return <p>ไม่พบข้อมูลสินค้า</p>;
    }
    return (
        <div className="product-details">
            <div className="product-main">
                <div className="product-image">
                    <img src={product.image} alt={product.name} />
                </div>
                <div className="product-info">
                    <h2>{product.name}</h2>
                    <p>ราคา: ฿{product.price}</p>
                    <div className="product-buttons">
                        <button>Add to Cart</button>
                        <button>Buy Now</button>
                    </div>
                </div>
            </div>

            <div className="store-info">
                <div className="product-store-profile">
                    <img src="/images/download.jpg" alt={product.store} className="product-store-image" />
                    <div className="product-store-details">
                        <h3>Watsons</h3>
                        <button onClick={onViewStore}>ดูร้านค้า</button>
                    </div>
                </div>
            </div>

            <div className="product-description">
                <h3>รายละเอียดสินค้า</h3>
                <h4>{product.brand}</h4>
                <p>{product.description}</p>
                <h4><p>จำนวนสินค้า: {product.quantity}</p>
                <p>ประเภทสินค้า: {product.category}</p></h4>

            </div>
        </div>
    );
}

export default ProductDetailWatsons;
