import React, {useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./components/homePage";
import StoreProfile from "./eveanboy-shop/src/components/StoreProfile";
import ProductDetail from "./eveanboy-shop/src/components/ProductDetail";
import Product from "./eveanboy-shop/src/components/Product";
import StoreProfileBoots from "./boots/components/StoreProfile";
import ProductListBoots from "./boots/components/ProductList";
import ProductBoots from "./boots/components/ProductBoots";
import StoreDetailWatsons from "./watsons/pages/StoreDetail";
import { productsWatsons } from "./watsons/data/productData";
import ProductDetailWatsons from "./watsons/pages/ProductDetail";
import ShopDetailBeautrium from "./beautrium/components/ShopDetail"
import ProductBeautrium from "./beautrium/components/Product"
function App() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSelectProduct = (product) => {
      setSelectedProduct(product);
  };
  return (
    <Router>
      <Header />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/eveanboy" element={<StoreProfile />} />
        <Route path="/eveanboy/products" element={<ProductDetail />} />
        <Route path="/eveanboy/product/:id" element={<Product />} />
        <Route path="/boots" element={<StoreProfileBoots />} />
        <Route path="/boots/products" element={<ProductListBoots />} />
        <Route path="/boots/product/:id" element={<ProductBoots />} />
        <Route
          path="/watsons"
          element={
            <StoreDetailWatsons
              store={{ productsWatsons }}
              onSelectProduct={handleSelectProduct}
            />
          }
        />{" "}
           <Route path="/watsons/product" element={<ProductDetailWatsons product={selectedProduct} />} />
           <Route path="/beautrium" element={<ShopDetailBeautrium/>} />
           <Route path="/beautrium/product" element={<ProductBeautrium/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
