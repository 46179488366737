import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../pages/StoreDetail.css';
import { productsWatsons } from '../data/productData'; 

function StoreDetailWatsons({ onSelectProduct }) {
    const navigate = useNavigate();

    const handleProductClick = (product) => {
        onSelectProduct(product);
        navigate('/watsons/product');
    };

    return (
        <div className="store-profile">
            
            <div className="store-header">
                <div className="store-image">
                    <img src="/images/download.jpg" alt="Store Profile" />
                </div>
                <div className="store-info">
                    <h2>Watsons</h2>
                    <p>ชอปสินค้าเพื่อความงามกับวัตสันออนไลน์</p>
                    <p>Facebook: Watsons</p>
                    <p>หากต้องการสอบถามเพิ่มเติม โปรดติดต่อที่ 02-017-8899 หรือ contactus@watsons.co.th</p>
                </div>
            </div>

            <div className="store-content">
                <h3 className="product-header">สินค้าทั้งหมดในร้าน</h3>
                <div className="product-grid">
                    {productsWatsons.map((product) => (  // ใช้ products จากการนำเข้ามา
                        <div
                            key={product.id}
                            className="product-item"
                            onClick={() => handleProductClick(product)}
                        >
                            <div className="products-image">
                               
                                <img
                                    src={product.image ? product.image : '/image/default.jpg'} 
                                    alt={product.name}
                                    className="product-item-image"
                                />
                            </div>
                            <h4>{product.name}</h4>
                            <p>฿{product.price}</p>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
}

export default StoreDetailWatsons;
